<template>
  <div class="knowledge_detail_container">
    <div class="breadcrumb_wrap m_1200 p_b_42">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
	<div class="width1200">
		<div class="knowledge_detail_title">{{newInfo.title}}</div>
		<div class="showVideo" @click="showVideo" v-if="url && type==2">查看视频</div>
		<div class="knowledge_detail_inner m_1200" v-html="content"></div>
	</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      newInfo: '',
      content: '',
	  url:'',
	  type:''
    }
  },
  mounted () {
	this.type=this.$route.query.type
    this.$http({
      method: 'POST',
      url: '/News/newsInfo',
      data: { id: this.$route.query.id }
    }).then(res => {
      this.newInfo = res.data
	  this.url=res.data.url
      this.content = res.data.content
    })
  },
  methods:{
	  showVideo(){
		window.open(this.url)
	  }
  }
}
</script>

<style lang="scss" scoped>
.knowledge_detail_container {
  padding: 42px 0;
  background: #f7f8fc;
  .width1200{
	width: 1200px;
	margin: 0 auto;
  }
  .knowledge_detail_title{
	  font-size: 16px;
  }
  .showVideo{
	background-color: #E51418;
	  color: #fff;
	  padding:10px 20px;
	  border-radius: 10px;
	  width: 80px;
	  text-align: center;
	  margin: 30px 0;
	  cursor: pointer;
  }
}
@media screen and (max-width: 768px) {
  .knowledge_detail_container {
    padding: 0 0 24px;
    background: #F7F8FC;
    .breadcrumb_wrap {
      box-sizing: border-box;
      padding: 15px;
    }
	.knowledge_detail_title{
		width: 95%;
		margin: 0 auto 10px;
	}
	.width1200{
		width: 100%;
	}
    .knowledge_detail_inner {
	  width: 95%;
	  padding: 0 2.5%;
    }  
	.knowledge_detail_inner /deep/ img{
		width: 100%!important;
	}
  }
}
</style>
